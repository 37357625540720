/* Styling for the Registration page */

.registration-container {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
  }
  
  .registration-form {
    flex: 1;
    margin-left: 2rem;
  }
  
  .registration-form h2 {
    margin-bottom: 1.5rem;
  }
  
  .registration-form div {
    margin-bottom: 1rem;
  }
  
  .registration-form label {
    display: block;
    margin-bottom: 0.5rem;
  }
  
  .registration-form input {
    width: 100%;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 3px 5px #000000;
  }
  
  .registration-form button {
    padding: 0.75rem 1.5rem;
    background-color: #82E1DF;
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    font-weight: bold;
    margin-top: 1rem;
  }
  
  .registration-form button:hover {
    background-color: #50b3b3;
  }
  
  .registration-image {
    flex: 1;
    margin-right: 2rem;
  }
  
  .registration-image img {
    width: 100%;
    border-radius: 10px;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  .success-message {
    color: green;
    font-weight: bold;
    margin-bottom: 1rem;
  }
  
  @media (max-width: 768px) {
    .registration-container {
      flex-direction: column-reverse;
    }
  
    .registration-image {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  
    .registration-form {
      margin-left: 0;
    }
  }
  
  