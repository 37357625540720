/* Style for the Forgot Password page  */

.forgot-password-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .forgot-password-container h1 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .forgot-password-container form {
    display: flex;
    flex-direction: column;
  }
  
  .forgot-password-container label {
    margin-bottom: 5px;
    text-align: left;
  }
  
  .forgot-password-container input {
    margin-bottom: 15px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: left;
  }
  
  .forgot-password-container button {
    padding: 10px 20px;
    background-color: #50c356;
    color: white;
    border: none;
    border-radius: 20px; /* Rounded edges */
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    align-self: flex-start;
    margin: 0 auto; /* Center the button */
  }
  
  .forgot-password-container button:hover {
    background-color: #218838; /* Slightly darker green on hover */
  }
  
  .forgot-password-container .input-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .forgot-password-container .input-group label {
    flex: 1;
    margin-bottom: 0;
  }
  
  .forgot-password-container .input-group input {
    flex: 2;
  }
  