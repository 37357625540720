/* General page styling for the standalone Edit Student page */

.edit-student-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  /* Form group styling */
  .form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
  }
  
  /* Label styling */
  .form-group label {
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  /* Input field styling */
  .form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    width: 100%;
  }
  
  /* Larger input fields for Address and Area of Study */
  .form-group input[name="address"],
  .form-group input[name="areaOfStudy"] {
    width: calc(100% - 20px);
  }
  
  /* Button styling */
  .button {
    padding: 10px 20px;
    border-radius: 20px;
    color: white;
    text-decoration: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
    display: inline-block;
    margin: 10px 0;
  }
  
  /* Edit button styles */
  .edit-btn {
    background-color: #50c356; /* Green color */
  }
  
  .edit-btn:hover {
    background-color: #218838; /* Slightly darker green on hover */
  }
  
  /* Success message styling */
  .success-message {
    color: green;
    font-weight: bold;
    font-size: 1.2em;
    margin-top: 20px;
  }
  