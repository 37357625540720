/* General styles */
.App {
  display: flex;
  flex-direction: column;
}

/* Main container and side panel styles */
.main-container {
  display: flex;
  flex: 1;
}

.side-panel {
  flex: 0 0 200px;
  background-color: #f4f4f4;
  padding: 1rem;
}

.side-panel ul {
  list-style-type: none;
  padding: 0;
}

.side-panel ul li {
  margin-bottom: 1rem;
}

.side-panel ul li a {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 0.5rem;
  background-color: #ddd;
  border-radius: 4px;
  text-align: center;
}

/* Content area styles */
.content {
  flex: 1;
  padding: 2rem;
}

/* Mobile navigation styles */
.side-panel-mobile {
  display: none; /* Hide mobile navigation by default */
}

@media (max-width: 768px) {
  .side-panel {
    display: none; /* Hide side panel on mobile devices */
  }

  .main-container {
    flex-direction: column;
  }

  .side-panel-mobile {
    display: flex; /* Show mobile navigation on small screens */
    justify-content: space-around;
    background-color: #f4f4f4;
    padding: 1rem;
  }

  .side-panel-mobile ul {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .side-panel-mobile ul li {
    margin-bottom: 0.5rem;
  }
}

