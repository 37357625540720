.about-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  /* Hero Section */
  .hero-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 50px;
  }
  
  .hero-image img {
    max-width: 50%;
    height: auto;
  }
  
  .hero-content {
    max-width: 45%;
    padding: 20px;
  }
  
  .hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 10px;
  }
  
  .hero-content p {
    margin-bottom: 20px;
  }
  
  /* Mission & Vision Section */
  .mission-vision-section {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .mission-vision-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .mission-vision-boxes {
    display: flex;
    justify-content: space-between;
  }
  
  .mission-vision-boxes .box {
    background-color: #f4f4f4;
    padding: 20px;
    margin: 10px;
    width: 30%;
    box-sizing: border-box;
    border-radius: 5px;
  }
  
  .mission-vision-boxes .box p {
    margin: 0;
  }
  
  /* Team Section */
  .team-section {
    text-align: center;
  }
  
  .team-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .team-member {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }
  
  .team-image img {
    max-width: 200px;
    margin-right: 20px;
    border-radius: 50%;
  }
  
  .team-content {
    max-width: 60%;
    text-align: left;
  }
  
  .team-content h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .team-content p {
    margin: 5px 0;
  }
  
  @media (max-width: 768px) {
    .hero-section {
      flex-direction: column;
      text-align: center;
    }
  
    .hero-image img {
      max-width: 100%;
      margin-bottom: 20px;
    }
  
    .hero-content {
      max-width: 100%;
    }
  
    .mission-vision-boxes {
      flex-direction: column;
      align-items: center;
    }
  
    .mission-vision-boxes .box {
      width: 100%;
      margin: 10px 0;
    }
  
    .team-member {
      flex-direction: column;
    }
  
    .team-image img {
      margin-bottom: 20px;
    }
  
    .team-content {
      max-width: 100%;
      text-align: center;
    }
  }
  