/* CSS to style the statistics on main Dashboard page */
.admin-info, .statistics, .important-info {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.admin-info p, .statistics p, .important-info p, .important-info li {
  margin: 10px 0;
}

.important-info {
  background-color: #e0f7fa; /* Light cyan background */
}

.important-info h3 {
  margin-bottom: 10px;
  color: #00796b;
}

.important-info ol {
  padding-left: 20px;
}