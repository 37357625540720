/* Style the login page */

.login-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
  }
  
  .login-form {
    flex: 1;
    max-width: 500px;
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 4px 10px #000000;
  }
  
  .login-form h2 {
    margin-bottom: 20px;
    color: #333;
  }
  
  .login-form form div {
    margin-bottom: 15px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  .login-form input {
    width: 90%;
    padding: 10px;
    margin-right: 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .login-form button {
    width: 50%;
    padding: 10px;
    background-color: #82E1DF;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .login-form button:hover {
    background-color: #70cfcf;
  }
  
  .login-image {
    flex: 1;
    max-width: 500px;
    padding: 20px;
  }
  
  .login-image img {
    width: 100%;
    border-radius: 8px;
  }
  
  .forgot-password-link {
    width: 50%;
    text-align: center;
    display: block;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
    padding: 10px;
  }
  
  .forgot-password-link:hover {
    text-decoration: underline;
  }

  /* Responsive Design */
@media (max-width: 768px) {
  .login-container {
      flex-direction: column;
  }

  .login-image {
      order: -1; /* Move the image to the top */
  }
}